import { gql, useMutation, useQuery } from '@apollo/client'

const ALL_BLUE_SPACES_QUERY = gql`
  query AllBlueSpaces {
    blue_spaces {
      id
      lat
      lng
      school {
        id
        name
        signed_manifesto
        action_plan
        marine_citizenship
        marine_identity
        deep_confidence
        memory_maps(
          limit: 1
        ) {
          id
        }
        sit_spots(
          limit: 1
        ) {
          id
        }
      }
    }
  }
`

const INSERT_BLUE_SPACE_WITH_SCHOOL = gql`
  mutation InsertBlueSpaceWithSchoolMutation(
    $lat: float8!, 
    $lng: float8!, 
    $schoolName: String!,
    $name: String!,
    $optIn: timestamptz,
    $optInOct: timestamptz,
    $userId: Int!,
  ) {
    insert_blue_spaces_one(
      object: {
        lat: $lat, 
        lng: $lng, 
        school: {
          data: {
            name: $schoolName
          }
        }
      }
    ) {
      school {
        id
        name
      }
      id
      lat
      lng
    }

    update_users_by_pk(
      pk_columns: {
        id: $userId
      }, 
      _set: {
        name: $name
        opt_in: $optIn
        opt_in_oct: $optInOct
      }
    ) {
      id
      name
      opt_in
      opt_in_oct
    }
  }
`

const UPDATE_BLUE_SPACE_WITH_SCHOOL = gql`
  mutation UpdateBlueSpaceWithSchoolMutation(
    $lat: float8!, 
    $lng: float8!, 
    $schoolName: String!,
    $name: String!,
    $optIn: timestamptz,
    $shouldUpdateOptIn: Bool,
    $optInOct: timestamptz,
    $shouldUpdateOptInOct: Bool,
    $userId: Int!,
    $schoolId: Int!,
    $blueSpaceId: Int!
  ) {
    update_blue_spaces_by_pk(
      pk_columns: {
        id: $blueSpaceId
      }, 
      _set: {
        lat: $lat, 
        lng: $lng, 
      }
    ) {
      id
      lat
      lng
    }

    update_schools_by_pk(
      pk_columns: {
        id: $schoolId
      }, 
      _set: {
        name: $schoolName
      }
    ) {
      id
      name
    }

    update_users_by_pk(
      pk_columns: {
        id: $userId
      }, 
      _set: {
        name: $name
      }
    ) {
      id
      name
      opt_in
      opt_in_oct
    }
  }
`

export const useAllBlueSpacesQuery = () => {
  const result = useQuery( ALL_BLUE_SPACES_QUERY )
  const blueSpaces = result?.data?.blue_spaces || []
  return { ...result, blueSpaces }
}

export const useInsertBlueSpaceWithSchool = () => {
  const [insertBlueSpaceWithSchool] = useMutation( INSERT_BLUE_SPACE_WITH_SCHOOL )
  return insertBlueSpaceWithSchool
}

export const useUpdateBlueSpaceWithSchool = () => {
  const [insertBlueSpaceWithSchool] = useMutation( UPDATE_BLUE_SPACE_WITH_SCHOOL )
  return insertBlueSpaceWithSchool
}
