import Head from 'next/head'
import { useAllBlueSpacesQuery } from '../graphql/blueSpaces'
import AppChrome from '../components/AppChrome'
import PageTitle from '../components/PageTitle'
import Map from '../components/Map'

function Home() {
  const { blueSpaces } = useAllBlueSpacesQuery()

  return (
    <AppChrome>
      <Head>
        <title>Our Blue — World Ocean Day for Schools</title>
      </Head>
      <PageTitle>
        Our Blue
      </PageTitle>
      <Map blueSpaces={ blueSpaces } />
    </AppChrome>
  )
}

export default Home
